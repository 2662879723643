import React from "react";
import styled from 'styled-components';
import CityChart from "../chart/CityChart";
import BasicLayout from '../BasicLayout';
import axios from 'axios';
import Loading from '../Loading';
import delhincr1 from "../../assets/images/delhincr1.png";
import mumbai from "../../assets/images/mumbai.png";
import hyderabad from "../../assets/images/hyderabad.png";
import chennai from "../../assets/images/chennai.png";
import bangalore from "../../assets/images/bangalore.png";
import jaipur from "../../assets/images/jaipur.png";
import kolkata from "../../assets/images/kolkata.png";
import city2 from "../../assets/images/city2.png";
import image from "../../assets/images/4.png";


const CITY_LIST = [
    {
       name: 'Ahmedabad',
       value: 'Ahmedabad',
       img: city2
    },
    {
       name: 'Bengaluru',
       img: bangalore
    },
    {
       name: 'Chandigarh',
       img: city2
    },
    {
       name: 'Chennai',
       img: chennai
    },
    {
       name: 'Hyderabad',
       img: hyderabad
    },
    {
       name: 'Jaipur',
       img: jaipur
    },
    {
       name: 'Kolkata',
       img: kolkata
    },
    {
       name: 'Lucknow',
       img: city2
    },
    {
       name: 'Mumbai',
       img: mumbai
    },
    {
       name: 'National-Capital-Region-NCR',
       img: delhincr1
    },
    {
       name: 'Pune',
       img: city2
    }];
 
class Cities extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            movieList: [],
            moviesData: [],
            cityList: CITY_LIST,
            selectedCity: "National-Capital-Region-NCR",
        }
   }

    async componentDidMount() {
        this.setState({
            loading: true,
        });
        const [firstResponse] = await Promise.all([
            axios.get(`https://appapi.deepflux.ai/get_movie_list`)
        ]);
        const movies = firstResponse.data.movies;
        this.setState({
            movieList: movies,
            loading: false,
        }, () => {
            this.fetchMovieDetails();
        });
    }

    fetchMovieDetails() {
        const moviePromises = [];
        for (let movie of this.state.movieList) {
            moviePromises.push(fetch(`https://appapi.deepflux.ai/mobile-screen/movie_city_detail?movie_name=${encodeURIComponent(movie)}&city_name=${this.state.selectedCity}`).then(res => res.json()));
        }
        Promise.all(moviePromises).then((allData) => {
            const finalData = [];
            for (let data of allData) {
                try {
                    let chartData = [];
                    data.data.map((item, key) => chartData.unshift(item.percent_occupancy))
                    const arraydata = data.data.slice(0)[0];
                    const arraydata2 = data.data.slice(-1)[0];
                    const showdata = arraydata.shows - arraydata2.shows;
                    const ticketdata = arraydata.avg_price - arraydata2.avg_price;
                    const occupancydata = arraydata.percent_occupancy - arraydata2.percent_occupancy;
                    finalData.push({
                        movieName: data.movie_name,
                        shows: arraydata.shows,
                        percentOccupancy: arraydata.percent_occupancy,
                        ticketPrice: arraydata.avg_price,
                        chartData: chartData,
                        showData: showdata,
                        ticketData: ticketdata,
                        occupancyData: occupancydata,
                    });
                } catch (error) {
                    console.log(error);
                }
            }
            this.setState({
                moviesData: finalData,
                loading: false
            });
        });
    }

    handleMovieNames = (movie) => {
        this.setState({
            selectedCity: movie,
            loading: true
        }, () => {
            this.fetchMovieDetails();
        });
    }

    render() {
        var settings = {
            dots: true,
            infinite: true,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        const movieCards = this.state.moviesData.map((movie) => {
            return (
                <div className="col-md-12" key={movie.movieName}>
                    <div className="card mb-3 gradient-primary" id="hero-card">
                        <div className="card-body ">
                            <div className="row">
                                <div className="col-8">
                                    <h5 className="city-title">{this.state.selectedCity}</h5>
                                </div>
                                <div className="col-4">
                                    {this.state.cityList.map((team) => <img src={team.name === this.state.selectedCity ? team.img : ""} height="59" alt="" className="ft-r" />)}
                                </div>
                            </div>
                        </div>
                        <div className="card-body bgs">
                            <div className="row">
                                <div className="col-6">
                                    <h2><span className="city-movie-title wrod-wrap1">{movie.movieName}</span></h2>
                                </div>

                                <div className="col-6">
                                    <img src={image} height="40" alt="" className="ft-r" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-center">
                                    <h1 className="bigfont">{movie.shows}</h1>
                                    <p>Shows</p>
                            <p>{ movie.showData > 0 ? <span class="green-text">{ "+"+movie.showData} </span>: <span class="red-text">{movie.showData} </span> }today</p>
                                </div>
                                <div className="col-6 text-center">
                                    <h1 className="bigfont">{parseInt(movie.ticketPrice)}₹</h1>
                                    <p>Ticket Price</p>
                                    <p>{movie.ticketData > 0 ? <span class="green-text">{"+"+parseInt(movie.ticketData)}</span> :<span class="red-text">{parseInt(movie.ticketData)}</span> } today</p>
                                </div>
                            </div>
                            <div className="row pd-1">
                                <div className="col-6">
                                {movie.chartData && movie.chartData && <CityChart chartdata = {movie.chartData}/>}
                                </div>
                                <div className="col-6 text-center ">
                                    <h1 className="bigfont">{parseFloat(movie.percentOccupancy).toFixed(2)}%</h1>
                                    <span>Occupancy</span><br />
                                    {movie.occupancyData > 0 ? <span class="green-text">{"+"+parseFloat(movie.occupancyData).toFixed(2)} </span> : <span class="red-text">{parseFloat(movie.occupancyData).toFixed(2)} </span> }
                                    <span>today</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })

        if (this.state.loading) {
            return <Loading />
        }
        return (
            <BasicLayout userName = {this.props.userName}>
                <Homes>
                    <div className="col-md-12 ">
                        <select className="form-control sel" value={this.state.selectedCity} onChange={(e) => this.handleMovieNames(e.target.value)}>
                            {this.state.cityList.map((city) => <option key={city.name} value={city.name}>{city.name}</option>)}
                        </select>
                    </div>

                    <div>
                        {movieCards}
                    </div>
                    )
                </Homes>
            </BasicLayout>
        );
    }
}
export default Cities
const Homes = styled.div`
margin-top:70px;
.bgs{
   background-color: #273247;
}

.slick-dots {
   bottom: -77px;
   
}

`;