import React, {Component} from 'react';
import {Line} from 'react-chartjs-2';
var ctx = document.getElementById('canvas').getContext("2d");

var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
gradientStroke.addColorStop(0, '#80b6f4');
gradientStroke.addColorStop(1, '#ffb142');

var gradientFill = ctx.createLinearGradient(0, 0, 0, 80);
gradientFill.addColorStop(0, "rgba(255, 177, 66, 0.6)");
gradientFill.addColorStop(1, "rgba(255, 177, 66, 0)");

class Chart3 extends Component{
  constructor(props){
    super(props);
    this.state = {
      chartData :{
        scaleLineColor: 'transparent',
        labels: ["JAN", "FEB", "MAR", "APR"],
            datasets: [{
            borderColor: gradientStroke,
            fill: true,
            backgroundColor: gradientFill,
            borderWidth: 2,
            data: [0, 20, 50, 70]   
            }]
      },
  }
}
componentDidMount(){
  // console.log(this.props.chartdata)

  this.setState({
    chartData :{
      scaleLineColor: 'transparent',
      labels: [...this.props.chartdata],
          datasets: [{
          borderColor: gradientStroke,
          fill: true,
          backgroundColor: gradientFill,
          borderWidth: 2,
          data: [...this.props.chartdata]   
          }]
    },
  })
}
  render(){
    return (
        
      <div className="chart">
        <Line
          data={this.state.chartData}
          options={{
            title:{
              display:this.props.displayTitle,
              text:'Largest Cities In '+this.props.location,
              fontSize:25
            },

            elements: {
              point:{
                  radius: 0
              }
            },
            legend:{
              display:this.props.displayLegend,
              position:this.props.legendPosition
            },
            scales: {
                xAxes: [{
                  gridLines: {
                    drawBorder: false,
                    display: false
                  },
                  ticks: {
                    display: false
                }
                }],
                yAxes: [{
                    gridLines: {
                      drawBorder: false,
                      display: false
                    },
                    ticks: {
                      display: false
                  }
                  }]
              },
          }}
        />
      </div>
    )
  }
}

export default Chart3;