import BasicLayout from '../BasicLayout';
import styled from 'styled-components';
import Performing from "./Performing";
import React, { Component } from 'react'

export default class Home extends Component {
      constructor() {
        super();
        this.state = {
        };
      }
  render() {
    return (
      <>
      <BasicLayout userName = {this.props.userName}>
        <div>
          <div>
        <Headers/>  
          </div>
            <Performing/>
          </div>
        </BasicLayout>
     </>
    )
  }
}

const Headers = styled.div`
margin-top:70px;
`;