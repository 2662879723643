import React from "react";
import Slider from "react-slick";
import Chart from "../chart/Chart3";
import axios from 'axios';
import Loading from '../Loading';
import image from "../../assets/images/4.png";
class MoviePerformance extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
         loading: false,
         moviesData: []
      };
   }

   async componentDidMount() {
      if (this.props.movieList.length < 1) return;
      this.setState({
         loading: true,
      });
      const moviePromises = [];
      for (let movie of this.props.movieList) {
         moviePromises.push(fetch(`https://appapi.deepflux.ai/mobile-screen/movie_detailed_info?movie_name=${encodeURIComponent(movie)}`).then(res => res.json()));
      }
      Promise.all(moviePromises).then((allData) => {
         const finalData = [];
         for (let data of allData) {
            if (data.data.length < 1) {
               continue;
            }
            try {
               const chartData = [];
               data.data.map((item, key) => chartData.unshift(item.percent_occupancy));
               const arraydata = data.data.slice(0)[0];
               finalData.push({
                  movieName: data.movie_name,
                  showCount: arraydata.shows,
                  occypancyPercentage: arraydata.percent_occupancy,
                  avgPrice: arraydata.avg_price,
                  chartData: chartData
               });
            } catch (error) {
               console.log(error);
            }
         }
         this.setState({
            moviesData: finalData,
            loading: false
         });
      });
   }

   render() {
      var settings = {
         dots: true,
         infinite: true,
         arrows: false,
         speed: 500,
         slidesToShow: 1,
         slidesToScroll: 1
      };

      const movieCards = this.state.moviesData.map((movie) => {
         return (
            <div className="col-md-12" key={movie.movieName}>
               <div className="swiper-content-block gradient-primary text-white shadow-15">
                  <div className="row">
                     <div className="col-8">
                        <p className="title-heading">{movie.movieName}</p>
                     </div>
                     <div className="col-4">
                        <img src={image} height="40" className="ft-r" alt="" />
                     </div>
                  </div>
                  <div className="row text-center mt-3">
                     <div className="col-6">
                        <h3 className="time-details text-uppercase  text-white">{isNaN(parseFloat(movie.occypancyPercentage)) === true ? "-" : parseFloat(movie.occypancyPercentage).toFixed(2)}%</h3>
                        <p className="title-child">Occupancy</p>
                     </div>
                     <div className="col-6">
                        {movie.chartData && movie.chartData && <Chart chartdata = {movie.chartData} />}
                     </div>
                  </div>
                  <div className="car-row row text-center">
                     <div className="col-6">
                        <h3 className="time-details text-uppercase  text-white">{movie.showCount}</h3>
                        <p>Shows</p>
                     </div>
                     <div className="col-6">
                        <h3 className="time-details text-uppercase  text-white">{isNaN(parseInt(movie.avgPrice)) === true ? "-" : parseInt(movie.avgPrice)}₹</h3>
                        <p>Ticket Price</p>
                     </div>
                  </div>
               </div>
            </div>
         )
      });

      if (this.state.loading) {
         return <Loading />;
      } else {
         return (
            <>
               <div className="col-12">
                  <h3 className="movie-type light-color">Performance</h3>
               </div>

               <Slider {...settings}>
                  {movieCards}
               </Slider>
            </>
         )
      }
   }
}
export default MoviePerformance
