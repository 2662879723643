import React from 'react'
import styled from 'styled-components';
import BasicLayout from '../BasicLayout';
import axios from 'axios';

class Answer extends React.Component  {
   constructor(props){
      super(props);
      this.state = {
        questions:[],
        anserr:'',
      }
    }

   componentDidMount(){
      axios.get("",{}).then((res)=>{
      //on success
      // this.setState({
      //    movieName:res.data,
      // });
      }).catch((error)=>{
      //on error
      console.log(error);
      console.log("There is an error in API call.");
      });
      this.setState({
         anserr:this.props.answer
      })
      }


   render(){
return (
   <BasicLayout userName = {this.props.userName}>
<Home >
   <div className="col-md-12">
      <div className="card mb-12 gradient-primary" id="hero-card">
         <div className="card-body">
            <div className="row">
               <div className="col-12">
            {this.props.answer ? <div className="car-p" dangerouslySetInnerHTML={{__html: this.state.anserr}} /> : 
                <p className="car-p">
                </p> }
               </div>
            </div>
         </div>
      </div>
   </div>
</Home>
</BasicLayout>
)
}
}
export default Answer
const Home = styled.div`
margin-top:70px;
`;