import React, { Component } from 'react'
import Navbar from '../components/navbar/Navbar2'
import Container from '@material-ui/core/Container';

 import ReactPullToRefresh from './ReactPullToRefresh'




class BasicLayout extends Component{
  constructor(props){
        super(props);
        this.state = {
          navbarOpen: false,
          'refreshing':false
        }
    
        this.startY = 0;
        this.endY = 0;
        this.scrollY = null;
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
        this.simulateRefreshAction2 = this.simulateRefreshAction2.bind(this);
    }
    handleTouchStart(e) {
      this.startY = e.touches[0].pageY;
    }

    handleTouchMove(e) {
      this.endY = e.touches[0].pageY;
      this.scrollY = window.scrollY;
    }

    handleTouchEnd(e) {
      if(this.scrollY === 0 && this.startY < 100 && this.endY > this.startY+200 && !this.state.refreshing) {
       this.simulateRefreshAction2();
      }
      this.startY = 0;
      this.endY = 0;
      this.scrollY = null;
    }

    simulateRefreshAction2() {
      const sleep = (timeout) => new Promise(resolve => setTimeout(resolve, timeout));
      this.setState({refreshing:true})
      window.location.reload();

      sleep(1500).then(() => {
        this.setState({refreshing:false})
      });
      if(this.props.onRefresh) {
        this.props.onRefresh()
      }
    }
  
    componentDidMount() {
  
      if(this.props.disableChromeDefaultRefresh) {
        let x = document.getElementsByTagName("BODY")[0];
        if(x) {
          x.style["overscroll-behavior-y"] = 'none';
        }
  
      }
  
      window.addEventListener('touchstart', this.handleTouchStart, {passive:true});
      window.addEventListener('touchmove', this.handleTouchMove, {passive:true});
      window.addEventListener('touchend', this.handleTouchEnd, {passive:true});
    }
  
    componentWillUnmount() {
      window.removeEventListener('touchstart', this.handleTouchStart, {passive:true});
      window.removeEventListener('touchmove', this.handleTouchMove, {passive:true});
      window.removeEventListener('touchend', this.handleTouchEnd, {passive:true});
    }
  handleNavbar = () => {
    this.setState({ navbarOpen: !this.state.navbarOpen });
  }
  render(){
    let showRefreshIcon = false;
    if(this.props.overrideRefresh) {
      showRefreshIcon = this.props.showRefresh;
    } else {
      showRefreshIcon = this.state.refreshing;
    }
  return (
        <Container maxWidth="sm">
        <Navbar userName = {this.props.userName ? this.props.userName : 'UserName'}/>
        <ReactPullToRefresh
          show={showRefreshIcon}
          onRefresh={this.handleRefresh}
          >
        </ReactPullToRefresh>
          {/* <PullToRefresh 
          pullDownContent={<PullDownContent />}
          releaseContent={<ReleaseContent />}
          refreshContent={<RefreshContent />}
          pullDownThreshold={200}
          onRefresh={this.handleRefresh}
          triggerHeight="100"
          backgroundColor='Transparent'> */}
         {/*    <div style={{overflowY:'scroll'}}> */}


          
          <article>
          {this.props.children}
          </article>
          
          {/* </div>*/}
          {/* </PullToRefresh>  */}
        
        </Container>
  );
  }
}

export default BasicLayout;
