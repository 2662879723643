import React from "react";
import Slider from "react-slick";
import NumericLabel from 'react-pretty-numbers';
import Loading from '../Loading';

var moment = require('moment');


class MovieTicketCollection extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
         moviesData: [],
         loading: false
      };
   }

   async componentDidMount() {
      if (this.props.movieList.length < 1) return;
      this.setState({
         loading: true,
      });
      const moviePromises = [];
      for (let movie of this.props.movieList) {
         moviePromises.push(fetch(`https://appapi.deepflux.ai/mobile-screen/movie_overall_detailed_info?movie_name=${encodeURIComponent(movie)}`).then(res => res.json()));
      }
      Promise.all(moviePromises).then((allData) => {
         const finalData = [];
         for (let data of allData) {
            try {
               let movie_data = data.data;
               let total_tickets = 0;
               let total_collections = 0;
               for (let show_data in movie_data) {
                  total_tickets += parseFloat(movie_data[show_data].tickets_sold || 0);
                  total_collections += parseFloat(movie_data[show_data].total_collection || 0);

               }
               total_tickets = total_tickets.toFixed(2);
               total_collections = total_collections.toFixed(2);

               let date_str = moment().format('YYYY-MM-DD');
               let todays_movie_data = movie_data[date_str].tickets_sold;
               let todays_movie_collection = movie_data[date_str].total_collection;
               finalData.push({
                  movieName: data.movie_name,
                  totalTicket: total_tickets,
                  todaysTicket: todays_movie_data,
                  totalCollection: total_collections,
                  todaysCollection: todays_movie_collection
               });
            } catch (error) {
               console.log(error);
            }
         }
         this.setState({
            moviesData: finalData,
            loading: false
         });
      });
   }

   render() {
      var settings = {
         dots: true,
         infinite: true,
         arrows: false,
         speed: 500,
         slidesToShow: 1,
         slidesToScroll: 1
      };

      const movieCards = this.state.moviesData.map((movie) => {
         return (
            <div className="col-md-12" key={`movieTicketCollection-${movie.movieName}`}>
               <div className="swiper-content-block gradient-primary text-white shadow-15">
                  <div className="row">
                     <div className="col-8">
                        <p className="title-heading">{movie.movieName}</p>
                     </div>
                     <div className="col-4">
                     </div>
                  </div>
                  <div className="row text-center mt-3">
                     <div className="col-6">
                        <span className="title-heading"><NumericLabel params={{ shortFormat: true, justification: 'L' }}>{movie.totalTicket}</NumericLabel></span><br />
                        <span className="title-child">Total Tickets Sold </span>
                     </div>
                     <div className="col-6">
                        <span className="title-heading">{movie.todaysTicket}</span><br />
                        <span className="title-child">Today’s Tickets Sold </span>
                     </div>
                  </div>
                  <br/>
                  <div className="car-row row text-center mt-3">
                     <div className="col-6">
                        <span className="title-heading">{movie.totalCollection}</span><br />
                        <span className="title-child">Total Collection (In ₹ Cr) </span>
                     </div>
                     <div className="col-6">
                        <span className="title-heading">{movie.todaysCollection}</span><br />
                        <span className="title-child">Today’s Collection (In ₹ Cr) </span>
                     </div>
                  </div>
               </div>
            </div>
         )
      })

      if (this.state.loading) {
         return <Loading/>
      } else {
         return (
            <>
               <div className="col-12">
                  <h3 className="movie-type light-color">Sales</h3>
               </div>
   
               <Slider {...settings}>
                  {movieCards}
               </Slider>
            </>
         );
      }

   }
}

export default MovieTicketCollection
