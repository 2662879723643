import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import BasicLayout from './BasicLayout';
import '../assets/custom.css'

const Loading = () => (
    <BasicLayout>
        <Box display="flex"
             alignItems="center"
             justifyContent="center"
             className="mt-5"
        >
            <Box>
                <CircularProgress className="loader-gif" />
            </Box>
        </Box>
    </BasicLayout>
);

export default Loading;
