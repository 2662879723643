import styled from 'styled-components';
import logo from "../../assets/logo-min.png";
import Amplify from 'aws-amplify';
import React, { Component } from 'react'
import Cookies from 'universal-cookie';
import { Auth } from 'aws-amplify';


export default class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: '',
            newPassword:'',
            signedIn: false,
            errmessage:'',
            FirstTimeSignInchangePasswordPopUpOpen:false,
            userObject:'',
            name:'',
            cnfcode:'',
            signIneventadded:false,
            ChangePasswordeventadded:false,
            IsResetPasswordPopupOpen:false,
            IsForgetPasswordChangePopUpOpen:false,
            IsPasswordChanged:false           
            
        }
        this.signIn = this.signIn.bind(this);

    }


handleChange=(e)=> {
    if (e.target.id === 'username') {
        this.setState({
            username: e.target.value
        })
    } 
    else if (e.target.id === 'password') {
        this.setState({
            password: e.target.value
        })
    }
    else if (e.target.id === 'newPassword') {
        this.setState({
            newPassword: e.target.value
        })
    }
    else if (e.target.id === 'name') {
        this.setState({
            name: e.target.value
        })
    }
    else if (e.target.id === 'cnfcode') {
        this.setState({
            cnfcode: e.target.value
        })
    }
}

    async signIn () {
        const { username, password, newPassword } = this.state
            try {
                const user = await Auth.signIn(username, password);
                if (user.challengeName === 'SMS_MFA' ||
                    user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    this.props.handleLogIn()
                    this.props.handleUserName(user.attributes['name'])
                    const cookies = new Cookies();
                    let today_date = new Date();
                    today_date.setDate(today_date.getDate() + 15);
                    cookies.set('session_token',user.signInUserSession.accessToken['jwtToken'], { expires: today_date })
                    cookies.set('username',user.attributes['name'], { expires: today_date })
                    this.setState({errmessage:""})
    
    
                } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    this.setState({userObject:user})
                    this.setState({FirstTimeSignInchangePasswordPopUpOpen:true})
                    this.setState({errmessage:""})
    
    
                } else if (user.challengeName === 'MFA_SETUP') {
                    Auth.setupTOTP(user);
                } else {
                    console.log(user);
                    this.props.handleLogIn()
                    this.props.handleUserName(user.attributes['name'])
                    const cookies = new Cookies();
                    let today_date = new Date();
                    today_date.setDate(today_date.getDate() + 15);
                    cookies.set('session_token',user.signInUserSession.accessToken['jwtToken'], { expires: today_date })
                    cookies.set('username',user.attributes['name'], { expires: today_date })
                    this.setState({errmessage:""})
                }
            } catch (err) {
                if (err.code === 'UserNotConfirmedException') {
                    this.setState({errmessage:err.message})
    
                } else if (err.code === 'PasswordResetRequiredException') {
                    this.setState({errmessage:err.message})
    
                } else if (err.code === 'NotAuthorizedException') {
                    this.setState({errmessage:err.message})

                } else if (err.code === 'UserNotFoundException') {
                    this.setState({errmessage:err.message})

                } else {
                    this.setState({errmessage:err.message})
                }
            }
    }

ChangePassword=()=>{
    const { username,password, newPassword,userObject,name } = this.state
    if(password===newPassword){
        Auth.completeNewPassword(userObject, newPassword, {"userAttributes.name": name})
        .then((res) => {
            if(res.signInUserSession.accessToken['jwtToken']){
            this.props.handleLogIn()
            const cookies = new Cookies();
            let today_date = new Date();
            today_date.setDate(today_date.getDate() + 15);
            cookies.set('session_token',res.signInUserSession.accessToken['jwtToken'], { expires: today_date })
            cookies.set('username',username, { expires: today_date })
            this.setState({errmessage:""})
            }


    })
    .catch((err) => {this.setState({errmessage:err.message})})

    }
    else{
        this.setState({errmessage:"The password and confirmation password do not match."})
    }

}
ForgetPasswordChangePopUp=()=>{
    this.setState({IsForgetPasswordChangePopUpOpen:true})
    }
ResetPasswordPopupHandler=()=>{
    Auth.forgotPassword(this.state.username)
    .then(data => {console.log(data)
    this.setState({errmessage:""})
    this.setState({IsResetPasswordPopupOpen:true})}
    )
    .catch(err => this.setState({errmessage:err.message})
    );
}

handleForgetPassword=()=>{

    Auth.forgotPasswordSubmit(this.state.username, this.state.cnfcode, this.state.newPassword)
    .then(data => {this.setState({IsPasswordChanged:true})
    this.setState({errmessage:""})
})
    .catch((err) => {this.setState({errmessage:err.message})})
}
ClosePopUp=()=>{
    this.setState({IsResetPasswordPopupOpen:false,IsForgetPasswordChangePopUpOpen:false})
}

componentDidMount(){
    Amplify.configure({
        Auth: {
            region: 'ap-southeast-1',

            identityPoolRegion: 'ap-southeast-1',
    
            userPoolId: 'ap-southeast-1_FK0dGCK5i',
    
            userPoolWebClientId: '6ia7gfuihhp0ntj6ub7fc299pc',
    
            authenticationFlowType: 'USER_PASSWORD_AUTH'
        }
    });


}


    render() {

        if(this.state.FirstTimeSignInchangePasswordPopUpOpen){
            return(
                <div>
                <Home>
<div className="row mx-0 h-100 justify-content-center">
    <div className="col-10 col-md-6 col-lg-4 my-3 mx-auto text-center align-self-center">
    <img src={logo} alt="Company Logo"  className="image"/>
    <span className="headline">Please Change Your Password</span>
        <div className="login-input-content">
            <div className="form-group">
                <input type="password" id ='password' className="form-control rounded text-center" placeholder="New Password" onChange={ this.handleChange }/>
            </div>
            <div className="form-group">
                <input type="password" id="newPassword" className="form-control rounded text-center" placeholder="Confirm Password" onChange={ this.handleChange }/>
            </div>
            <div className="form-group">
                <input type="password" id="name" className="form-control rounded text-center" placeholder="Name" onChange={ this.handleChange }/>
            </div>

            <a className="btn btn-block btn-success rounded border-0 z-3" onClick={this.ChangePassword}>Submit</a>
        </div>
        <br/>
        <br/>
    <span className="err-message">{this.state.errmessage}</span>                
    </div>
</div>
</Home>
            </div>
            )
        }    else if(this.state.IsResetPasswordPopupOpen){
            return(
                <Home>
<div className="row mx-0 h-100 justify-content-center">
    <div className="col-10 col-md-6 col-lg-4 my-3 mx-auto text-center align-self-center">
    <img src={logo} alt="Company Logo" className="image"/>
            <div>
                <div>
                {this.state.IsPasswordChanged ===true ?<div><h4>Your Password is Successfully Changed !</h4>
                             <button className="btn btn-block btn-success rounded border-0 z-3" onClick={this.ClosePopUp }>Sign In</button>
                             </div>
                             :<div>{this.state.IsResetPasswordPopupOpen ? null : <h4>Reset Password</h4> }
                    {this.state.IsForgetPasswordChangePopUpOpen ===true ?
                    <span className="headline">Please Enter Your New Password</span>
                    :
                    <span className="headline">A 6-digit confirmation code is sent to your registered email address</span>
                }
        <div className="login-input-content">
            {this.state.IsForgetPasswordChangePopUpOpen === true ?
            <div> 
                <div className="form-group"> 
                <input type="password" id="password" class="form-control rounded text-center" placeholder="New Password" autoComplete="off"  onChange={ this.handleChange }/>
                </div>
                <div className="form-group"> 
                <input type="password" id="newPassword" class="form-control rounded text-center" placeholder="Confirm Password" autoComplete="off"  onChange={ this.handleChange }/>
                </div>
                </div>
                :
                <div className="form-group">
                <input type="text" id="cnfcode" class="form-control rounded text-center" placeholder="_ _ _ _ _ _" autoComplete="off"  onChange={ this.handleChange }/>
            </div>
            }
            </div>
            <span className="err-message">{this.state.errmessage}</span>
            <div className="login-input-content">
                {this.state.IsForgetPasswordChangePopUpOpen===true?       
                 <button className="btn btn-block btn-success rounded border-0 z-3" onClick={this.handleForgetPassword }>Submit</button>
                :  <button className="btn btn-block btn-success rounded border-0 z-3" onClick={this.ForgetPasswordChangePopUp }>Next</button>}
            </div>
                </div>}
                </div>
            </div> 
          </div>
          </div>
          </Home>
            )
        }
        else{
        return (
            <div>
                <Home>
<div className="row mx-0 h-100 justify-content-center">
    <div className="col-10 col-md-6 col-lg-4 my-3 mx-auto text-center align-self-center">
    <img src={logo} alt="Company Logo"  className="image"/>
        <div className="login-input-content ">
            <div className="form-group">
                <input type="email" id ='username' className="form-control rounded text-center" placeholder="Username" onChange={ this.handleChange }/>
            </div>
            <div className="form-group">
                <input type="password" id="password" className="form-control rounded text-center" placeholder="Password" onChange={ this.handleChange }/>
            </div>
            <a  className="btn btn-block btn-success rounded border-0 z-3" onClick={this.signIn}>Sign in</a>
        </div>
        <br/>
        <br/>
    <span className="err-message">{this.state.errmessage}</span>
        <div className="row justify-content-center">
            <div className="col-6 "><a  className="text-white mt-3" onClick={this.ResetPasswordPopupHandler}>Reset Password</a></div>
        </div>                  
    </div>
</div>
</Home>
            </div>
        )}
    }
}






const Home = styled.div`
margin-top:10%;
.btn{
    margin-top:20px;
}

&nav{
   dispay:none !important;
}
`;
