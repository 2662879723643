import styled from 'styled-components';
import BasicLayout from '../BasicLayout';

import React, { Component } from 'react'

export default class List extends Component {
  render() {
    return (
      <BasicLayout userName = {this.props.userName}>
      <Home >
      
          
         <div className="">
         <ul className="ulstyle">
         <a href="/Details"><li>Notification 1</li></a>
       <a href="/Details"> <li>Notification 2</li></a>
       <a href="/Details"><li>Notification 3</li></a>
       <a href="/Details"> <li>Notification 4</li></a>
          </ul>
         </div>
      </Home>
      </BasicLayout>
    )
  }
}



const Home = styled.div`
margin-top:70px;
.ulstyle{
    list-style: none;
    background: #242e42;
    border-radius: 0;
}
.ulstyle li {
  padding: 2%;
  margin: 1%;
  border-bottom: 1px solid #12161f;
  margin-bottom: 2%;
  border-radius: 0;
  color:#fff;
  font-size:2.4vh;
  font-weight: 300;
  font-family: Roboto;
}

`;