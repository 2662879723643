import React from 'react'
import styled from 'styled-components';
import BasicLayout from '../BasicLayout';
import axios from 'axios';
import Answer from './Answer';
import Loading from '../Loading';
import Button from 'react-bootstrap/Button'


class Questions extends React.Component {


  constructor(props){
    super(props);
    this.state = {
      questions:[],
      answer:'',
      loading: false

    }
    this.refreshpage = this.refreshpage.bind(this);
  }

componentDidMount(){
  fetch("https://appapi.deepflux.ai/mobile-screen/questions")
  .then((response)=> {
    if(response.status===200){
      return response.json();
    }
  })
  .then((myJson)=> {

this.setState({questions:myJson})
})




}
handleQuestion=(url)=>{
  this.setState({
    loading: true,
    });

  axios.get(url,{}).then((res)=>{
      this.setState({
         answer:res.data,
         loading: false,
      });
      }).catch((error)=>{

      });
      
}


refreshpage(){
  this.setState({
    answer:''
  })
}

render(){
  const {loading} = this.state;

if (loading) {
   return <Loading/>
}
return (
  <BasicLayout userName = {this.props.userName}> 




    {this.state.answer === '' ? <Home>


    
   <div className="">
   <ul className="ulstyle">


   {this.state.questions.length>0 ? this.state.questions.map((questionn,index)=>
   {
    return(
       <li onClick={()=>{this.handleQuestion(questionn.value)}}>{questionn.label}</li>
      
    );

   }): null
   }
    </ul>
   </div>
   </Home>
   : < Answer answer = {this.state.answer}/>}

{this.state.answer !== '' ?<div className="col-md-12 "><Button style={{backgroundColor:'Transparent',borderColor:'Transparent',margin:'0 auto',marginTop:'112%',marginLeft:'2%',borderRadius:'7px'}} onClick={this.refreshpage}>Back</Button> </div>: ""}
</BasicLayout>
)
}
}

export default Questions
const Home = styled.div`
margin-top:70px;
.ulstyle{
    list-style: none;
    background: #242e42;
    border-radius: 0;
}
.ulstyle li {
  padding: 2%;
  margin: 1%;
  border-bottom: 1px solid #12161f;
  border-radius: 0;
  color:#fff;
  font-size:2.4vh;
  font-weight: 300;
  font-family: Roboto;
}

`;