import React, {Component} from 'react';
import {Bar} from 'react-chartjs-2';
class Chart extends Component{
  constructor(props){
    super(props);
    this.state = {
      chartData :{
        labels:['Mon', 'Tue', 'Wed', 'Thu','Fri','Sat','Sun'],
        datasets:[{
            label:'Population',
            borderWidth: 0,
            borderRadius: 10,
            data:[
              8,
              7,
              10,
              5,
              13,
              3,
              2,
              1
            ],
            //backgroundColor:'green',
          backgroundColor:[
            '#706fd3',
            '#706fd3',
            '#706fd3',
            '#706fd3',
            '#706fd3',
            '#706fd3',
            '#ffb142',
          ],
          borderColor:'#777'
          
        }]
      }
    }
  }

  componentDidMount(){
  
    this.setState({
      chartData :{
        scaleLineColor: 'transparent',
        labels:['', '', '', '','','','','','',''],
        datasets:[{
          label:'Population',
          borderWidth: 0,
          borderRadius: 10,
            data: this.props.chartdata,
            backgroundColor:[
              '#706fd3',
              '#706fd3',
              '#706fd3',
              '#706fd3',
              '#706fd3',
              '#706fd3',
              '#ffb142',
              '#ffb142',
              '#ffb142',
              '#ffb142',
            ],
            borderColor:'#777'  
        }]
      },
    })
  }


  render(){
    return (
        
      <div className="chart">
        <Bar
          data={this.state.chartData}
          options={{
            title:{
              display:this.props.displayTitle,
              text:'Largest Cities In '+this.props.location,
              fontSize:15
            },
            legend:{
              display:this.props.displayLegend,
              position:this.props.legendPosition
            },
            scales: {
                xAxes: [{
                  gridLines: {
                    drawBorder: false,
                    display: false
                  },
                  ticks: {
                 
                }
                }],
                yAxes: [{
                    gridLines: {
                      drawBorder: false,
                      display: false
                    },
                    ticks: {
                      display: false
                  }
                  }]
                }
          }}
        />
      </div>
    )
  }
}

export default Chart;