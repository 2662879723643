import React from "react";
import Chart from "../chart/Chart";
import axios from 'axios';
import Loading from '../Loading';
import delhincr1 from "../../assets/images/delhincr1.png";
import mumbai from "../../assets/images/mumbai.png";
import hyderabad from "../../assets/images/hyderabad.png";
import chennai from "../../assets/images/chennai.png";
import bangalore from "../../assets/images/bangalore.png";
import jaipur from "../../assets/images/jaipur.png";
import kolkata from "../../assets/images/kolkata.png";
import city2 from "../../assets/images/city2.png";

const CITY_LIST = [
   {
      name: 'Ahmedabad',
      value: 'Ahmedabad',
      img: city2
   },
   {
      name: 'Bengaluru',
      img: bangalore
   },
   {
      name: 'Chandigarh',
      img: city2
   },
   {
      name: 'Chennai',
      img: chennai
   },
   {
      name: 'Hyderabad',
      img: hyderabad
   },
   {
      name: 'Jaipur',
      img: jaipur
   },
   {
      name: 'Kolkata',
      img: kolkata
   },
   {
      name: 'Lucknow',
      img: city2
   },
   {
      name: 'Mumbai',
      img: mumbai
   },
   {
      name: 'National-Capital-Region-NCR',
      img: delhincr1
   },
   {
      name: 'Pune',
      img: city2
   }];

class SimpleSlider extends React.Component {
   constructor() {
      super();
      this.state = {
         cityList: CITY_LIST,
         movieList: [],
         movieData: [],
         selectedCity: "Bengaluru",
         selectedmovie: '',
      };
   }

   async componentDidMount() {
      this.setState({
         loading: true,
      });

      const [firstResponse] = await Promise.all([
         axios.get(`https://appapi.deepflux.ai/get_movie_list`)
      ]);
      const movies = firstResponse.data.movies;
      if (movies.length > 0) {
         this.setState({
            movieList: movies,
            selectedmovie: movies[0]
         }, () => {
            this.fetchMovieDetails();
         });
      }
   }

   fetchMovieDetails() {
      let today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const todaysdate = today.getDate();
      today = `${year}-${month}-${todaysdate}`;
      this.setState({
         loading: true
      });

      axios.get(`https://appapi.deepflux.ai/cinemalevel?movie=${encodeURIComponent(this.state.selectedmovie)}&city=${this.state.selectedCity}&show_date=${today}`, {})
         .then((res) => {
            if (res.data) {
               this.setState({
                  movieData: res.data.data,
                  loading: false,
               });
            }
         }).catch((error) => {
            console.log(error);
         });
   }

   handleMovieNames(cityName, movieName) {
      console.log(cityName, movieName);
      this.setState({
         selectedCity: cityName,
         selectedmovie: movieName
      }, () => {
         this.fetchMovieDetails();
      });
   }


   render() {

      if (this.state.loading) {
         return <Loading/>
      } else if (this.state.movieData.length > 0) {

      }
      if(Array.isArray(this.state.movieData)){
      return (
         <div>
         <div className="col-md-12 select-padding-bottom">
            <select className="form-control sel" value={this.state.selectedCity} onChange={(e) => this.handleMovieNames(e.target.value, this.state.selectedmovie)}>
               {this.state.cityList.map((city) => <option key={city.value} value={city.value}>{city.name}</option>)}
            </select>
         </div>
   
         <div className="col-md-12 select-padding-bottom">
            <select className="form-control sel" value={this.state.selectedmovie} onChange={(e) => this.handleMovieNames(this.state.selectedCity, e.target.value)}>
               {this.state.movieList.map((movie) => <option key={movie} value={movie}>{movie}</option>)}
            </select>
         </div>

         { this.state.movieData.length > 0 ? this.state.movieData.map((data,i)=>{
            return(
            <div className="col-md-12 cdp">
            <div className="card mb-3 gradient-primary" id="hero-card">
               <div className="card-header">
                  <div className="row">
                     <div className="col-9">
                        <h1 className="title-heading t-title-heading wrod-wrap">{data.venue_name}</h1>
                     </div>
                     <div className="col-3">
                     {this.state.cityList.map((team) => <img src={team.name === data.city ? team.img : ""} height="59" alt=""  className="ft-r"/> )}
                     </div>
                  </div>
               </div>
               <div className="card-body ">
                  <div className="row">
                     <div className="col-6 text-center">
            <h1 className="title-heading">{data.percent_occupancy.toFixed(2)}%</h1>
                        <h6 className="title-child">Occupancy</h6>
                        {/* <p className="title-child"><span className="green-text"> +13</span> Today</p> */}
                     </div>
                     <div className="col-6">
                        
                     <Chart  chartdata = {data.occupancy_trend} key={i} />
                     </div>
                  </div>
               </div>
               <div className="card-body ">
                  <div className="row text-center">
                     <div className="col-6">
            <h1><span className="title-heading font-weight-light small">{data.shows}</span></h1>
                        <p><span className="title-child font-weight-light small">Shows</span></p>
                     </div>
                     <div className="col-6">
                        <h1 className="title-heading">
                           <span className=" font-weight-light small"></span><b>{data.avg_price.toFixed(2)}</b>
                        </h1>
                        <p><span className="title-child font-weight-light small">Ticket Price</span></p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
            )
         }):null}
         
         </div>
             );
      }
   }

}

export default SimpleSlider
