import React, {Component} from 'react';
//import './SwipeRefreshLayout.css';

class ReactPullToRefresh extends Component {
  render() {
    let show = this.props.show;
    const classes = ['refresher'];

    if(show) {
      classes.push('refreshing');
    } else {
      // classes.remove('refreshing')
      classes.push('refreshing');
      classes.push('hideRefreshing');
    }
    return(
      <div
        id = {"containerRefresher"}
        className={classes.join(' ')}>
        <div className="loading-bar"></div>
        <div className="loading-bar"></div>
        <div className="loading-bar"></div>
        <div className="loading-bar"></div>
      </div>
    )
  }

}

export default ReactPullToRefresh;


// import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import WebPullToRefresh from './wptr.1.1';

// export default class ReactPullToRefresh extends Component {

//   constructor(props) {
//     super(props);
//     this.state = {
//       initialized: false
//     };
//     this.handleRefresh = this.handleRefresh.bind(this);
//   }

//   handleRefresh() {
//     return new Promise((resolve, reject) => {
//       this.props.onRefresh(resolve, reject);
//     });
//   }

//   init() {
//     if (!this.state.initialized) {
//       WebPullToRefresh().init({
//         contentEl: this.refresh,
//         ptrEl: this.ptr,
//         bodyEl: this.body,
//         distanceToRefresh: this.props.distanceToRefresh || undefined,
//         loadingFunction: this.handleRefresh,
//         resistance: this.props.resistance || undefined,
//         hammerOptions: this.props.hammerOptions || undefined
//       });
//       this.setState({
//         initialized: true
//       });
//     }
//   }

//   componentDidMount() {
//     if (!this.props.disabled) {
//       this.init();
//     }
//   }

//   componentDidUpdate() {
//     if (!this.props.disabled) {
//       this.init();
//     }
//   }

//   render() {
//     const {
//       children,
//       disabled,
//       distanceToRefresh,
//       hammerOptions,
//       icon,
//       loading,
//       onRefresh,
//       resistance,
//       ...rest
//     } = this.props;

//     if (disabled) {
//       return (
//         <div {...rest}>
//           {children}
//         </div>
//       );
//     }

//     return (
//       <div ref={(el) => { this.body = el; }} {...rest}>
//         <div ref={(el) => { this.ptr = el; }} className="ptr-element">
//           {icon || <span className="fa fa-arrow-circle-o-down" style={{color:'White',margin:'0 auto'}}></span>}
//           {loading ||
//             <div className="loading" style={{margin:'0 auto'}}>
//               <span className="loading-ptr-1"></span>
//               <span className="loading-ptr-2"></span>
//               <span className="loading-ptr-3"></span>
//            </div>}
//         </div>
//         <div ref={(el) => { this.refresh = el; }} className="refresh-view">
//           {children}
//         </div>
//       </div>
//     );
//   }
// }

// ReactPullToRefresh.propTypes = {
//   onRefresh: PropTypes.func.isRequired,
//   icon: PropTypes.element,
//   loading: PropTypes.element,
//   disabled: PropTypes.bool,
//   className: PropTypes.string,
//   style: PropTypes.object,
//   distanceToRefresh: PropTypes.number,
//   resistance: PropTypes.number,
//   hammerOptions: PropTypes.object
// };