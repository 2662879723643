import BasicLayout from '../BasicLayout';
import styled from 'styled-components';
import React, { Component } from 'react'
import Chart from "../chart/Chart";
import axios from 'axios';

 class Card extends Component {
   constructor() {
      super();
      this.state = {
      };
    }
   render() {
      return (
       
          <div className="col-md-12 cdp">
         <div className="card mb-3 gradient-primary" id="hero-card">
            <div className="card-header"  onClick={()=>{this.props.handletoggle(this.props.key)}}>
               <div className="row">
                  <div className="col-9" id="drowdownindicator">
      <h1 className="title-heading t-title-heading wrod-wrap">{this.props.movieName}</h1>
                  </div>
                  <div className="col-3" >
                   
                     <img className="drp-dwn" src='dropdown-arrow.png' />
                  </div>
               </div>
            </div>
            <div className="card-body ">
               <div className="row">
                  <div className="col-6 text-center">
      <h1 class="bigfont">{this.props.avg}</h1>
                    <span class="green-text">{this.props.from + ' to ' + this.props.to}</span>
                     <h6 className="title-child">Prediction (in Cr)</h6>
                  </div>
                  <div className="col-6">
                  <img src={this.props.img} height="59" alt="" class="ft-r"/>
                  </div>
               </div>
                <div>  <div className="expand">
      <div className="list"><h6 className="title-child">BMS Momentum</h6><div><span className="comment-text">{this.props.bms_likes_momentum}</span><span class="green-text"></span></div></div>
         <hr/>
      <div className="list"><h6 className="title-child">Daily Searches</h6><div><span className="comment-text">{this.props.daily_searches}</span><span class="red-text"></span></div></div>
         {/* <hr/> */}
         {/* <div className="list"><h6 className="title-child">Social Buzz</h6><div><span className="comment-text">350</span><span class="green-text">+37</span></div></div> */}
         {/* <hr/> */}
         {/* <div className="list"><h6 className="title-child">PAYTM Momentum</h6><div><span className="comment-text">350</span><span class="red-text">-19</span></div></div> */}
</div>
<div className="comment">
<h6 className="title-child">Comments</h6>
<span className="comment-text">
      {this.props.comments}</span>
</div></div>
            </div>
         </div>

         
      </div>       
      )
   }
}

export default class Prelaunch extends Component {
      constructor() {
        super();
        this.state = {
           active:[],
           movieData:[]
        };
      }

handletoggle=(index)=>{
   console.log("<--------index--------->",index)
//    let mutablearray = this.state.active
//    var mutable = mutablearray
// if(mutable.includes(index)){
//    let sliced = mutable.slice(index)
//    this.setState({active:sliced})
// }
// else{
//    let pushed = mutable.push(index)
//    this.setState({
//       active:pushed
//    })
// }
   }
componentDidMount(){
   axios.get('https://appapi.deepflux.ai/prelaunch',{})
   .then((res)=>{
      if(res.data){
         console.log("<-----res.data---->",res.data)
         this.setState({
            movieData:res.data,
            loading: false,
         });
      }

   }).catch((error)=>{
    console.log(error);
   });
}
getImageUrl=(production_house)=>{
   if(production_house==="SKF"){
      return 'skf.png'
   }
   if(production_house==='Dharma'){
      return 'dharma.png'
   }
   if(production_house==="Sony"){
      return 'sony.png'
   }
   if(production_house==="YRF"){
      return 'yrf.png'
   }
   if(production_house==="Disney"){
      return 'disney.png'
   }
   if(production_house==="Universal"){
      return 'universal.png'
   }
   if(production_house==="T-Series"){
      return 't-series.png'
   }
   if(production_house==="Warner"){
      return 'warner.png'
   }

}
  render() {
    return (
      <div>
      <BasicLayout userName = {this.props.userName}>
 <div>
   <div>
 <Headers/>  
   </div>
      </div>
      {this.state.movieData.length > 0 ? this.state.movieData.map((data,i)=>{
         return(
            <Card movieName = {data.movie_name}
             from = {data.lower_prediction}
             to = {data.upper_prediction}
             avg={data.mle}
             img = {this.getImageUrl(data.production_house)}
             key={i}
             handletoggle = {this.handletoggle}
             active = {this.state.active}
             bms_likes_momentum = {data.bms_likes_momentum}
             daily_searches = {data.daily_searches}
             comments = {data.comments}
             />
         )
      }) : null }

 </BasicLayout>
  </div>


    )
  }
}

const Headers = styled.div`
margin-top:70px;
`;