import React from 'react';
import styled from 'styled-components';
import MoviePrediction from "./SimpleSlider1";
import MoviePerformance from "./SimpleSlider";
import MovieTicketCollection from "./MovieTicketCollection";

//for image testing 
import axios from 'axios';
import morning from "../../assets/images/morning.png";
import afternoon from "../../assets/images/afternoon.png";
import evening from "../../assets/images/evening.png";
import night from "../../assets/images/night.png";


class Carousel extends React.Component {
   constructor() {
      super();
      this.state = {
         movieList: [],
         greeting: '',
         imggreeting: ''
      };
   }

   async componentDidMount() {
      var today = new Date();
      var curHr = today.getHours();
      if (curHr < 11) {
         this.setState({
            greeting: 'Good Morning',
            imggreeting: morning
         });
      } else if (curHr < 18) {
         this.setState({
            greeting: 'Good Afternoon',
            imggreeting: afternoon
         });
      } else if (curHr < 22) {
         this.setState({
            greeting: 'Good Evening',
            imggreeting: evening
         });
      } else {
         this.setState({
            greeting: 'Good Night',
            imggreeting: night
         });
      }

      const [firstResponse] = await Promise.all([
         axios.get(`https://appapi.deepflux.ai/get_movie_list`)
      ]);
      const movies = firstResponse.data.movies;
      this.setState({
         movieList: movies
      });

   }



   render() {
      return (
         this.state.movieList.length > 0 &&
         <Home >

            <div className="row m-3 p-2">
               <div className="col-9">
                  <div className="row">
                     <h3>
                        {this.state.curHr}
                        <span className="font-weight-light good-morning small" >{this.state.greeting}</span>
                     </h3>
                  </div>
                  <br/>
                  <div className="row">
                     <h1 className="persion-name light-color">{this.props.userName}</h1>
                  </div>
               </div>
               <div className="col-3">
                  {/* image url imported from assets */}
                  <img src={this.state.imggreeting} className="img-respnsive" alt="" />
               </div>
            </div>

            <MovieTicketCollection movieList={this.state.movieList}/>
            <br/>
            <MoviePrediction movieList={this.state.movieList}/>
            <br/>
            <MoviePerformance movieList={this.state.movieList}/>

         </Home>
      )
   }
}
export default Carousel
const Home = styled.div`
margin-top:70px;
.bgs{
background-color: #273247;
}
.good-morning{
font-size:24px;
color: #cfd7db !important;
}
.movie-title{
padding: 3% 3%;
}
`;