import ReactDOM from 'react-dom';
import React  from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import Home from './components/home/Home'
import Top from './components/topperforming/Top'
import Cities from './components/cities/Cities2'
import Question from './components/question/Question'
import Answer from './components/question/Answer'
import Notification from './components/notification/List'
import Details from './components/notification/Details'
import Search from './components/search/Search'
import GlobalStyle from './styles/Global';
import Login from "./components/login/Login";
import App from "./App";


  
ReactDOM.render(<App/>, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
