import React from "react";
import Slider from "react-slick";
import image1 from "../../assets/images/3.png";
import image from "../../assets/images/2.png";
import Loading from '../Loading';


class MoviePrediction extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
         moviesData: [],
         loading: false
      };
   }

   async componentDidMount() {
      if (this.props.movieList.length < 1) return;
      this.setState({
         loading: true,
      });
      const moviePromises = [];
      for (let movie of this.props.movieList) {
         moviePromises.push(fetch(`https://disneyrealtimeapi.deepflux.ai/prediction?movie=${encodeURIComponent(movie)}`).then(res => res.json()));
      }
      Promise.all(moviePromises).then((allData) => {
         const finalData = [];
         for (let data of allData) {
            if (data.movie_name) {
               finalData.push(data);
            }
         }
         this.setState({
            moviesData: finalData,
            loading: false
         });
      });
   }

   render() {
      var settings = {
         dots: true,
         infinite: true,
         arrows: false,
         speed: 500,
         slidesToShow: 1,
         slidesToScroll: 1
      };

      const movieCards = this.state.moviesData.map((movie) => {
         return (
            <div className="col-md-12" key={movie.movie_name}>
               <div className="swiper-content-block gradient-primary text-white shadow-15">
                  <div className="row">
                     <div className="col-8">
                        <p className="title-heading">{movie.movie_name}</p>
                     </div>
                     <div className="col-4">
                        <img src={image} height="40" alt="" className="ft-r" />
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-12">
                        <img src={image1} alt="" className="img-center text-center" />
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-12">
                        <h3 className="money-title text-center">{isNaN(parseFloat(movie.unscaled_prediction)) === true ? "-" : parseFloat(movie.unscaled_prediction).toFixed(2)}</h3>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-12">
                        <p className="title-heading text-center">Cr ₹</p>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-12">
                        <h3 className="time-details green-text font-weight-bold text-center" >{isNaN(parseFloat(movie.lower_limit)) === true ? "-" : parseFloat(movie.lower_limit).toFixed(2)} to {isNaN(parseFloat(movie.upper_limit)) === true ? "-" : parseFloat(movie.upper_limit).toFixed(2)}</h3>
                     </div>
                  </div>
               </div>
            </div>
         )
      })

      if (this.state.loading) {
         return <Loading/>
      } else {
         return (
            <>
               <div className="col-12">
                  <h3 className="movie-type light-color">Prediction</h3>
               </div>

               <Slider {...settings}>
                  {movieCards}
               </Slider>
            </>
         );
      }
   }
}

export default MoviePrediction
