import React from 'react';
import '../../assets/style.css';
import '../../assets/bootstrapmin.css';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
// import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Brand from "./Brand"; 
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import styled from "styled-components";
import { Link } from 'react-router-dom';

// import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Avatar from '@material-ui/core/Avatar';
import Cookies from 'universal-cookie';


const useStyles = makeStyles(theme => ({
  paper: {
    background: '#242e42',
    color: 'white'
  },
  list: {
    width: 250,
    color:'#fff'
  },
  fullList: {
    width: 'auto',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));

export default function Navbar(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });
  const theme = useTheme();

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className={classes.drawerHeader}>
      <Avatar variant="rounded" className={classes.rounded}>
        {props.userName.split('')[0]}
      </Avatar>
  <ListItemText > {props.userName}</ListItemText>

        <IconButton onClick={toggleDrawer('left', false)}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon style={{ color: '#FFFFFF' }} /> : <ChevronRightIcon style={{ color: '#FFFFFF' },{ fontSize:'24' }} />}
        </IconButton>
      </div>
      <Divider/>
      <List>
          <Link  to={'/Home'}  >
          <ListItem button style={{ color: '#FFFFFF' }} className="drawer-fs">
              <ListItemIcon className="minwd"><i class="fa fa-home" style={{ color: '#FFFFFF' }} aria-hidden="true"></i></ListItemIcon>
              <ListItemText >Home Page</ListItemText>
              </ListItem>
            </Link >
            <Divider />
            <Link to="/Cities" >
            <ListItem button style={{ color: '#FFFFFF' }} className="drawer-fs">
                <ListItemIcon className="minwd"><i class="fa fa-bars" style={{ color: '#FFFFFF' }} aria-hidden="true"></i></ListItemIcon>
                <ListItemText >Cities</ListItemText>
                </ListItem>
            </Link>
            <Divider />
            <Link to="/Top" button >
            <ListItem button style={{ color: '#FFFFFF' }} className="drawer-fs">
                <ListItemIcon className="minwd"><i class="fa fa-film" style={{ color: '#FFFFFF' }} aria-hidden="true"></i></ListItemIcon>
                <ListItemText >Top Performing</ListItemText>
                </ListItem>
            </Link>
            <Divider />
            <Link to="/Question" button >
            <ListItem button style={{ color: '#FFFFFF' }} className="drawer-fs">
                <ListItemIcon className="minwd"><i class="fa fa-question" style={{ color: '#FFFFFF' }} aria-hidden="true"></i></ListItemIcon>
                <ListItemText>Questions</ListItemText>
                </ListItem>
            </Link>
            <Divider />
            <Link to="/Prelaunch" button >
            <ListItem button style={{ color: '#FFFFFF' }} className="drawer-fs">
                <ListItemIcon className="minwd"><i class="fa fa-line-chart" style={{ color: '#FFFFFF' }} aria-hidden="true"></i></ListItemIcon>
                <ListItemText>Pre Launch</ListItemText>
                </ListItem>
            </Link>
            <Divider/>
            <Link to="/List" button >
            <ListItem button style={{ color: '#FFFFFF' }} className="drawer-fs">
                <ListItemIcon className="minwd"><i class="fa fa-bell" style={{ color: '#FFFFFF' }} aria-hidden="true"></i></ListItemIcon>
                <ListItemText  >Notifications</ListItemText>
                </ListItem>
            </Link>
            <Divider />
            <ListItem onClick={()=>{ window.open('mailto:manas@deepflux.io?subject=Help in the Movie App', '_blank');}} button style={{ color: '#FFFFFF' }} className="drawer-fs">
                <ListItemIcon className="minwd"><i class='fa fa-question-circle' style={{ color: '#FFFFFF' }} aria-hidden="true"></i></ListItemIcon>
                <ListItemText>Help</ListItemText>
            </ListItem>
            <Divider />
            <ListItem onClick={()=>{document.location.reload(true); document.location.href=''}} button style={{ color: '#FFFFFF' }} className="drawer-fs">
                <ListItemIcon className="minwd"><i class='fa fa-refresh' style={{ color: '#FFFFFF' }} aria-hidden="true"></i></ListItemIcon>
                <ListItemText>Reload</ListItemText>
            </ListItem>
            <Divider />
            <ListItem onClick={()=>{const cookies = new Cookies()
                              cookies.remove('session_token');
                              document.location.reload(true)}} button style={{ color: '#FFFFFF' }} className="drawer-fs">
                <ListItemIcon className="minwd"><i class='fa fa-sign-out' style={{ color: '#FFFFFF' }} aria-hidden="true"></i></ListItemIcon>
                <ListItemText  >SignOut</ListItemText>
            </ListItem>
      </List>
      <Divider />
    </div>
  );

  

  return (
    
    <div>

<AppBar
        position="fixed"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer('left', true)}
            edge="start"
            style={{ fontSize:30 }}
          >
            <i class="fa fa-bars  menu-bar" aria-hidden="true"></i>
          </IconButton>
          <Typography variant="h6" noWrap className="text-center">
          <Brand/>
          </Typography>

          <Link to="/Search" className="pull-right"><i className="fa fa-search" aria-hidden="true"></i></Link>
        </Toolbar>
      </AppBar>
      <Drawer
        open={state.left} onClose={toggleDrawer('left', false)} classes={{ paper: classes.paper }}
            >
        {sideList('left')}

      </Drawer>
 {/* <FlexContainer>
          <BurgerWrapper>
            <Button onClick={toggleDrawer('left', true)}><i class="fa fa-bars  menu-bar" aria-hidden="true"></i></Button>
            <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
              {sideList('left')}
              
            </Drawer>
            
          <Brand/>
          </BurgerWrapper>
          <BurgerWrapper>
        <a href ="/Search"><i className="fa fa-search" aria-hidden="true"></i></a>
        </BurgerWrapper>
        </FlexContainer> */}

      {/* <Button onClick={toggleDrawer('left', true)}><i class="fa fa-home" aria-hidden="true"></i> </Button>
      <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
        {sideList('left')}
      </Drawer> */}
    </div>
  );
}

// const FlexContainer = styled.div`
//   max-width: 120rem;
//   display: flex;
//   margin: auto;
//   padding: 0 2rem;;
//   justify-content: space-between;
//   height: 8vh;
// `;


 
// const BurgerWrapper = styled.div`
//   margin: auto 0;

//   @media (min-width: 769px) {
//   }
// `;