import styled from 'styled-components';
import BasicLayout from '../BasicLayout';


import React, { Component } from 'react'

export default class Details extends Component {
   render() {
      return (
         <div>
               <BasicLayout userName = {this.props.userName}>
<Home >
   <div className="col-md-12">
      <div className="card mb-12 gradient-primary" id="hero-card">
         <div className="card-body">
            <div className="row">
               <div className="col-12">
                <p className="car-p">What weird food combinations do you really enjoy
                What weird food combinations do you really enjoy
                What weird food combinations do you really enjoy
                What weird food combinations do you really enjoy
                What weird food combinations do you really enjoy
                What weird food combinations do you really enjoy
                </p>
               </div>
            </div>
         </div>
      </div>
   </div>
</Home>
</BasicLayout>
         </div>
      )
   }
}

const Home = styled.div`
margin-top:70px;
`;