import React from 'react';
import BasicLayout from '../BasicLayout';
import Select from 'react-select';
import axios from 'axios';

const customStyles = {
  input:()=>({
    marginTop: '5px',
    color:'#fff'
  }),
  valueContainer:()=>({
    marginLeft:'10px',
    fontColor:'#fff',
    backgroundColor: '#242e42'
  }),
  indicatorSeparator:()=>({
    display:'None'
  })
,selectContainer:()=>()=>({
  backgroundColor: '#242e42',
  fontColor:'#fff',
}),
  indicatorsContainer:()=>({
    display:'None'
  }),

  dropdownIndicator:()=>({
  display:'None'
  }),

  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #86919d',
    backgroundColor:'#242e42',
    color:'#fff'
  }),
container:()=>({
  backgroundColor: '#242e42',
  top:'0'
}),
placeholder:()=>({
  color:'#fff',
  marginTop:'6%'
}),
  control: () => ({
    marginTop: '15%',
    display:'flex',
    height: '33px' ,
    backgroundColor: '#242e42',
    borderRadius: '5px',
    border: '3px #5a5caa solid',
 })
,
  singleValue: (provided, state) => {
    const opacity = 1;
    const transition = 'opacity 300ms';
    const color= '#fff';
    const top = '11%';
    return { ...provided, opacity, transition,color,top };
  },
  dropdownIndicator:()=>({
    height:40
  }),
  menu:()=>({
    fontColor:'#fff',
    height:'100%'
  })

}

class Example extends React.Component {
  constructor() {
    super();
    this.state = {
      suggestions: [],
      data: '',
      answer:null,
    };
  }





  componentDidMount() {

    fetch("https://appapi.deepflux.ai/mobile-screen/questions")
    .then((response)=> {
      if(response.status===200){
        return response.json();
      }
    })
    .then((myJson)=> {

  this.setState({data:myJson})
})

  }

  handleChange = (e) => {
    
    this.setState({ selectedOption:e.label });
    let answer_url = e.value;
    axios.get(answer_url,{}).then((res)=>{
      this.setState({
         answer:res.data
      });
      }).catch((error)=>{

      });


}

  render() {

    const { selectedOption } = this.state;

    return (
      <BasicLayout userName = {this.props.userName}>
        {Array.isArray(this.state.data)?
        <div className="overall-wrapper">
        <div className="select-wrapper">
        <Select
          isSearchable = {true}
          styles={customStyles}
          options={this.state.data}
          onChange={this.handleChange}
          placeholder = 'Search here'
        />
        </div>
        {this.state.answer !== null ? <div class="card mb-12 gradient-primary" id="answer">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="car-p" dangerouslySetInnerHTML={{__html: this.state.answer}}>
                </div>
              </div>
            </div>
          </div>
        </div> : null }
        
        </div>
        :"Loading"}
        
      </BasicLayout>
    )
  }
}
export default Example


