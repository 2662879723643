import { Route, BrowserRouter as Router } from 'react-router-dom'
import React, { Component } from 'react'
import Home from './components/home/Home'
import Top from './components/topperforming/Top'
import Cities from './components/cities/Cities2'
import Question from './components/question/Question'
import Answer from './components/question/Answer'
import Notification from './components/notification/List'
import Details from './components/notification/Details'
import Search from './components/search/Search'
import GlobalStyle from './styles/Global'
import Login from './components/login/Login'
import Cookies from 'universal-cookie'
import Prelaunch from './components/prelaunch/Prelaunch'



export default class App extends Component {
      constructor() {
        super()
        this.state = {
          LoggedIn:false,
          userName:'User'
        }
      }


  handleLogIn=()=>{
    this.setState({LoggedIn:true})
  }
  handleUserName=(username)=>{
    this.setState({userName:username.toUpperCase()})
  }
  shouldComponentUpdate(nextProps, nextState){
     return this.state.userName !== nextState.userName
 }
  componentDidMount(){
     const cookies = new Cookies()
     const token = cookies.get('session_token')
     const username = cookies.get('username')
     if(username){
     this.setState({userName:username}) 
     }
   if(token){
     this.setState({LoggedIn:true})
}
  }

  render() {
    return (
      <Router>
      <div>
        <Route exact path="/"  render={() => {
        if (this.state.LoggedIn) 
             {return <Home userName = {this.state.userName}/>}
        else 
             {return <Login handleLogIn = {this.handleLogIn} handleUserName={this.handleUserName}/>}
      }}/>
        <div className="dashboard">
        <Route exact path="/Home"  render={() => {
        if (this.state.LoggedIn) 
             {return <Home userName = {this.state.userName}/>}
        else 
             {return <Login handleLogIn = {this.handleLogIn} handleUserName={this.handleUserName} userName = {this.state.userName}/>}
      }}/>
        <Route exact path="/Top"  render={() => {
        if (this.state.LoggedIn) 
             {return <Top userName = {this.state.userName}/>}
        else 
             {return <Login handleLogIn = {this.handleLogIn} handleUserName={this.handleUserName} userName = {this.state.userName}/>}
      }}/>
        <Route exact path="/Cities"  render={() => {
        if (this.state.LoggedIn) 
             {return <Cities userName = {this.state.userName}/>}
        else 
             {return <Login handleLogIn = {this.handleLogIn} handleUserName={this.handleUserName}/>}
      }}/>        
        <Route exact path="/Question"  render={() => {
        if (this.state.LoggedIn) 
             {return <Question userName = {this.state.userName}/>}
        else 
             {return <Login handleLogIn = {this.handleLogIn} handleUserName={this.handleUserName}/>}
      }}/>         
        <Route exact path="/Answer"  render={() => {
        if (this.state.LoggedIn) 
             {return <Answer userName = {this.state.userName}/>}
        else 
             {return <Login handleLogIn = {this.handleLogIn} handleUserName={this.handleUserName}/>}
      }}/>         
        <Route exact path="/List"  render={() => {
        if (this.state.LoggedIn) 
             {return <Notification  userName = {this.state.userName}/>}
        else 
             {return <Login handleLogIn = {this.handleLogIn} handleUserName={this.handleUserName}/>}
      }}/>
       <Route exact path="/Prelaunch"  render={() => {
        if (this.state.LoggedIn) 
             {return <Prelaunch  userName = {this.state.userName}/>}
        else 
             {return <Login handleLogIn = {this.handleLogIn} handleUserName={this.handleUserName}/>}
      }}/>      
        <Route exact path="/Details"  render={() => {
        if (this.state.LoggedIn) 
             {return <Details  userName = {this.state.userName}/>}
        else 
             {return <Login handleLogIn = {this.handleLogIn} handleUserName={this.handleUserName}/>}
      }}/>        
        <Route exact path="/Search"  render={() => {
        if (this.state.LoggedIn) 
             {return <Search  userName = {this.state.userName}/>}
        else 
             {return <Login handleLogIn = {this.handleLogIn} handleUserName={this.handleUserName}/>}
      }}/>          
        <Route exact path="/Login"  render={() => {
        if (this.state.LoggedIn) 
             {return <Home userName = {this.state.userName}/>}
        else 
             {return <Login handleLogIn = {this.handleLogIn} handleUserName={this.handleUserName}/>}
      }}/>       
        </div>
      </div>
      <GlobalStyle />
    </Router>
    )
  }
}



