import React,{ Component } from "react";
import Carousel from "./Carousel";
import BasicLayout from '../BasicLayout';


class Home extends Component {
  constructor() {
    super()
    this.state = {

    }
  }
 render(){
 return (
    <BasicLayout userName ={this.props.userName}>
       <div>
      <Carousel userName ={this.props.userName ? this.props.userName : ''}/>
      </div>
    </BasicLayout>
  )
}
}
export default Home
